/**
 * Ensure the presence of the canonical URL in the store.
 * If the canonical URL is not available, it fetches it from the server.
 * This middleware runs only on the server side to avoid unnecessary
 * client-side execution and ensure SEO metadata is prepared for bots.

 * @returns {Promise<void>} Resolves when the canonical URL is ensured in the store.
 */
import { GAME_MODES } from '@/constants/game';

export default defineNuxtRouteMiddleware(async (to) => {
  const isRealModeGamePage = to.path.includes('/game/') && to.params.mode === GAME_MODES.real;

  if (import.meta.client || isRealModeGamePage) {
    return;
  }

  const globalStore = useGlobalsStore();
  const { fetchCanonicalUrlForSSR } = globalStore;
  const { getCanonicalUrl } = storeToRefs(globalStore);

  if (!getCanonicalUrl.value) {
    await fetchCanonicalUrlForSSR();
  }
});
